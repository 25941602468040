ion-icon {
  &[name*='inforce-'] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    color: currentColor;
    width: 24px;
    height: 24px;
  }

  &[name*='inforce-user-icon'] {
    mask-image: url(/assets/user.svg);
  }

  &[name*='inforce-password-icon'] {
    mask-image: url(/assets/password.svg);
  }

  &[name*='inforce-warning-icon'] {
    mask-image: url(/assets/warning.svg);
    stroke: white;
    fill: white;
  }

  &[name*='inforce-location-icon'] {
    mask-image: url(/assets/location.svg);
    stroke: black;
    fill: black;
  }

  &[name*='inforce-shield-icon'] {
    mask-image: url(/assets/shield.svg);
    stroke: black;
    fill: black;
  }

  &[name*='inforce-reverse-alert'] {
    mask-image: url(/assets/reverse-alert.svg);
    stroke: black;
    fill: black;
  }
}
