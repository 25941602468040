// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ffffff;
  --ion-color-primary-rgb: 255, 255, 255;
  --ion-color-primary-contrast: #162149;
  --ion-color-primary-contrast-rgb: 22, 33, 73;
  --ion-color-primary-shade: #e0e0e0;
  --ion-color-primary-tint: #ffffff;

  /** secondary **/
  --ion-color-secondary: #acbce1;
  --ion-color-secondary-rgb: 172, 188, 225;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #97a5c6;
  --ion-color-secondary-tint: #b4c3e4;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #c01717;
  --ion-color-danger-rgb: 192, 23, 23;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a91414;
  --ion-color-danger-tint: #c62e2e;

  /** dark **/
  --ion-color-dark: pink;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #162149;
  --ion-color-light-contrast-rgb: 22, 33, 73;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  /** custom colors **/

  // custom colors declared with only css variables are used only in css
  --blue: #3880ff;
  --dark-blue: #162149;
  --very-light-blue: #dce0f2;
  --dark-red: #b50000;
  --light-red: #f5d7d7;
  --transparent-white: rgba(255, 255, 255, 0.3);
  --less-transparent-white: rgba(255, 255, 255, 0.7);
  --transparent-grey: rgba(100, 100, 100, 0.7);
  --grey: #c1c1c1;
  --light-grey: #eaeaea;
  --dark-grey: #949494;
  --purple: #827f95;

  // custom colors declared with ion-color-xx can be used in templates
  /** light grey **/
  --ion-color-light-grey: #eaeaea;
  --ion-color-light-grey-rgb: 234, 234, 234;
  --ion-color-light-grey-contrast: #d0d0d0;
  --ion-color-light-grey-contrast-rgb: 208, 208, 208;
  --ion-color-light-grey-shade: #e0e0e0;
  --ion-color-light-grey-tint: #ffffff;

  /** light blue **/
  --ion-color-light-blue: #acbce1;
  --ion-color-light-blue-rgb: 172, 188, 225;
  --ion-color-light-blue-contrast: #505776;
  --ion-color-light-blue-contrast-rgb: 80, 87, 118;
  --ion-color-light-blue-shade: #97a5c6;
  --ion-color-light-blue-tint: #b4c3e4;

  /** blue **/
  --ion-color-blue: #0078d6;
  --ion-color-blue-rgb: 0, 120, 214;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #006abc;
  --ion-color-blue-tint: #1a86da;

  /** component styling **/
  --ion-toolbar-background: transparent;
}

.ion-color-light-grey {
  --ion-color-base: var(--ion-color-light-grey) !important;
  --ion-color-base-rgb: var(--ion-color-light-grey-rgb) !important;
  --ion-color-contrast: var(--ion-color-light-grey-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-grey-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-light-grey-shade) !important;
  --ion-color-tint: var(--ion-color-light-grey-tint) !important;
}

.ion-color-light-blue {
  --ion-color-base: var(--ion-color-light-blue) !important;
  --ion-color-base-rgb: var(--ion-color-light-blue-rgb) !important;
  --ion-color-contrast: var(--ion-color-light-blue-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-blue-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-light-blue-shade) !important;
  --ion-color-tint: var(--ion-color-light-blue-tint) !important;
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue) !important;
  --ion-color-base-rgb: var(--ion-color-blue-rgb) !important;
  --ion-color-contrast: var(--ion-color-blue-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-blue-shade) !important;
  --ion-color-tint: var(--ion-color-blue-tint) !important;
}
