// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './theme/icons.scss';

@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/nunito.ttf');
}

@font-face {
  font-family: 'Nunito-Bold';
  src: url('./assets/fonts/nunito-bold.ttf');
}

* {
  --ion-font-family: 'Nunito', sans-serif;
}

.ion-page {
  background-image: url('/assets/login-bg.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  background-attachment: fixed;
  background-color: #000;
}

ion-content {
  --background: none;
}

ion-header {
  --background: none;
  ion-title {
    --background: none;
    color: #fff;
    font-weight: normal;
  }
}

ion-button {
  --border-radius: 5px;
  font-size: 22px;
  --ion-font-family: 'Nunito-Bold', sans-serif;
  letter-spacing: 0.1px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert-button-inner {
  color: var(--blue);
}
